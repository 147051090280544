import React, { useState, useEffect } from "react";
import "./Banner.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SlideOne from '../../../assets/img/asna-images/asna-banner1.jpeg';
import SlideTwo from '../../../assets/img/asna-images/asna-banner2.jpeg';

const Banner = () => {
  const [textContent, setTextContent] = useState('Window Treatments');

  useEffect(() => {
    const interval = setInterval(() => {

      setTextContent(prevText => {
        switch (prevText) {
          case 'Window Treatments':
            return 'House Construction';
          case 'House Construction':
            return 'House Renovation ';
          case 'House Renovation':
            return 'Window Treatments';
          default:
            return 'Window Treatments';
        }
      });
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
  };
  const slideshowImages = [
    { url: SlideOne },
    { url: SlideTwo }
  ]

  return (
    <>
    <div className="relative home-banner">
      <Slider {...settings}>
        {slideshowImages.map((image, index) => (
          <div key={index} style={{width: '100%', height: '600px'}} className="h-[450px] sm:h-[600px]">
            <div
             className="h-[450px] sm:h-[600px] slide-media"
              style={{
                backgroundImage: `url(${image.url})`,
                width: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: '#000'
              }}>
                <div className="gr-container text-[#fff] relative h-full flex flex-col justify-end sm:justify-center items-start pb-[60px] sm:pb-0 px-4 md:px-2 lg:px-0">
                  <h1 id="banner-hdg" className="text-[32px] sm:text-[100px]"> 
                    <span className="animated-word"> {textContent} </span> </h1>
                  <p> From concept to creation, we shape spaces and illuminate views, one project at a time. </p>
                </div>
              </div>
          </div>
        ))}
      </Slider>
    </div>
    </>
  );
};

export default Banner;


