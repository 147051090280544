import ResPro1 from '../../../assets/img/asna-images/windows/Wood-Blinds.jpg';
import ResPro2 from '../../../assets/img/asna-images/windows/Faux-Wood-Blinds.jpg';
import ResPro3 from '../../../assets/img/asna-images/windows/Vertical-Blinds.jpg';
import ResPro4 from '../../../assets/img/asna-images/windows/Aluminum-Blinds.jpg';
import ResPro5 from '../../../assets/img/asna-images/windows/Solar-Shades.jpg';
import ResPro6 from '../../../assets/img/asna-images/windows/Sheer-Shades.jpg';
import ResPro7 from '../../../assets/img/asna-images/windows/Outdoor-Patio-Shades.jpg';

const ResidenceProjectData = [
    {
        id: 0,
        imgSrc: ResPro1,
        projectType: '',
        projectTitle: 'Wood Blinds',
        text: 'Premium wood blinds that bring a touch of natural elegance and warmth to any room, enhancing both style and functionality'
    },
    {
        id: 1,
        imgSrc: ResPro2,
        projectType: '',
        projectTitle: 'Faux Wood Blinds',
        text: 'Faux wood blinds combine the classic look of real wood with enhanced durability and moisture resistance, perfect for adding timeless beauty to any space.',
        // textList: [ 
        //     { id: 0, LiText: 'Aluminum and Glass Doors and Windows'},
        //     { id: 1, LiText: 'Slim Sliding and Fix Panels'},
        // ]
    },
    {
        id: 2,
        imgSrc: ResPro3,
        projectType: '',
        projectTitle: 'Vertical Blinds',
        text: 'Vertical blinds are an ideal solution for large windows and sliding doors, providing excellent light control and a sleek, modern aesthetic.'
    },
    {
        id: 3,
        imgSrc: ResPro4,
        projectType: '',
        projectTitle: 'Aluminum Blinds',
        text: 'A sleek and contemporary window treatment solution, combining durability with a stylish, minimalist design to complement any modern space.'
    },
    {
        id: 4,
        imgSrc: ResPro5,
        projectType: '',
        projectTitle: 'Solar Shades',
        text: 'Effective protection against harsh sunlight while maintaining a clear view of the outdoors, making them an excellent choice for optimizing both comfort and energy efficiency in your space.'
        // textList: [ 
        //     { id: 0, LiText: 'Aluminum and Glass Doors and Windows'},
        //     { id: 1, LiText: 'Slim Sliding and Fix Panels'},
        // ]
    },
    {
        id: 5,
        imgSrc: ResPro6,
        projectType: '',
        projectTitle: 'Sheer Shades',
        text: 'A delicate balance of light filtering and privacy, creating a soft and elegant ambiance in any room'
    },
    {
        id: 6,
        imgSrc: ResPro7,
        projectType: '',
        projectTitle: 'Outdoor Patio Shades',
        text: 'Transforming your outdoor space with stylish and functional sun-blocking solutions, ensuring comfort and enjoyment in any weather.'
    }
]
export default ResidenceProjectData;