import React from "react";
import AssuranceBg from "../../../assets/img/asna-images/assurance-bg.jpeg";
import Durable from "../../../assets/svg/durable.svg";
import Reliable from "../../../assets/svg/reliable.svg";
import Cost from "../../../assets/svg/cost-effective.svg";
import "./Assurance.css";
import BluryLoadingImage from "../../Loaders/BluryLoadingImage";

const Assurance = () => {
  const AssuranceBgStyle = { backgroundImage: `url(${AssuranceBg})` };
  return (
    <section className="w-full assurance-main" style={AssuranceBgStyle}>
      <div className="gr-container py-[100px] sm:py-[170px]">
        <div className="flex flex-col sm:flex-row justify-around items-center gap-[40px] sm:gap-[30px] text-[#fff]">
          <div className="flex flex-col items-center justify-center !z-[99]">
            <BluryLoadingImage
              image={Durable}
              alt="missing"
              imageStyleClass="w-[100px] sm:w-[130px] h-[100px] sm:h-[130px] mb-4"
            />
            <h2> Durable </h2>
          </div>
          <div className="flex flex-col items-center justify-center !z-[99]">
            <BluryLoadingImage
              image={Reliable}
              alt="missing"
              imageStyleClass="w-[100px] sm:w-[130px] h-[100px] sm:h-[130px] mb-4"
            />
            <h2> Reliable </h2>
          </div>
          <div className="flex flex-col items-center justify-center !z-[99]">
            <BluryLoadingImage
              image={Cost}
              alt="missing"
              imageStyleClass="w-[100px] sm:w-[130px] h-[100px] sm:h-[130px] mb-4"
            />
            <h2> Cost-effective </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Assurance;
