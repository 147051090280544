import PublicArt from "../../../assets/img/home-renovation.jpg";
import { Link } from "react-router-dom";
import BluryLoadingImage from "../../Loaders/BluryLoadingImage";

const MetalWorks = () => {
  return (
    <section className="gr-container py-[60px] sm:py-[100px]">
      <div className="flex flex-col gap-[20px] sm:gap-[60px]">
        <div className="px-4 sm:px-2 lg:px-0"> ||||||||||||||||||||| </div>
        <h2 className="text-[32px] sm:text-[62px] leading-[36px] sm:leading-[68px] px-4 sm:px-2 lg:px-0">
          House Renovation.
        </h2>
        <div className="flex flex-col sm:flex-row relative">
          <div className="w-full sm:w-[50%] mt-0 sm:mt-[90px] pt-[60px] pb-[60px] pl-4 sm:pl-6 pr-4 sm:pr-[150px] bg-[#eeeff0] flex flex-col gap-[20px] sm:gap-[40px]">
            <h3 className="text-[32px] sm:text-[38px] leading-[36px] sm:leading-[50px]">
              Revitalize Your Space
            </h3>
            <p className="font-helvatica-medium">
              Your house is a reflection of your lifestyle and personal taste.
              Our dedicated team of renovation experts is here to breathe new
              life into your space, whether it's updating outdated features or
              completely transforming the layout. From kitchen remodels to
              bathroom renovations and everything in between, we approach each
              project with creativity, skill, and attention to detail. We work
              closely with you to understand your vision and tailor our services
              to meet your specific needs and budget. With a focus on quality
              materials and meticulous craftsmanship, we'll revitalize your
              home, enhancing its functionality, comfort, and aesthetic appeal.
              Let us help you fall in love with your home all over again.
            </p>
            <Link
              to="/house-renovation"
              className="gr-btn w-max"
            >
              View More
            </Link>
          </div>
          <div className="w-full sm:w-[50%] relative sm:absolute right-0 sm:right-[80px]">
            <BluryLoadingImage image={PublicArt} alt="House Renovation" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MetalWorks;
