import MainPro1 from '../../../assets/img/asna-images/renovation/Revitalize-Your-Space.jpg';
import MainPro2 from '../../../assets/img/asna-images/renovation/Renew-Your-Kitchen.jpg';
import MainPro3 from '../../../assets/img/asna-images/renovation/Upgrade-with-Confidence.jpg';
import MainPro4 from '../../../assets/img/asna-images/renovation/renovation4.jpeg';
import MainPro5 from '../../../assets/img/asna-images/renovation/renovation5.jpeg';
import MainPro6 from '../../../assets/img/asna-images/renovation/Modernize-Your-Home.jpg';
import MainPro7 from '../../../assets/img/asna-images/renovation/Revamp-with-Style.jpg';


const MetalWorksProjectData = [
    {
        id: 0,
        imgSrc: MainPro1,
        projectType: '',
        projectTitle: 'Revitalize Your Space',
        text: 'Revitalize your space with our expertly crafted window treatments, designed to enhance both style and functionality, bringing new life and elegance to every room.'
    },
    {
        id: 1,
        imgSrc: MainPro2,
        projectType: '',
        projectTitle: "Renew Your Kitchen",
        text: 'Renew your kitchen with our premium window treatments, adding a blend of style and practicality to create a more inviting and functional culinary space.'
    },
    {
        id: 2,
        imgSrc: MainPro3,
        projectType: '',
        projectTitle: 'Upgrade with Confidence',
        text: 'Upgrade your ensuite with our elegant and moisture-resistant window treatments, enhancing both style and privacy in your private retreat.'
    },
    {
        id: 3,
        imgSrc: MainPro4,
        projectType: '',
        projectTitle: 'Transform Your Living Space',
        text: 'Transform your living space with our exquisite window treatments, adding a touch of elegance and functionality to every room, elevating the ambiance and comfort of your home.'
    },
    {
        id: 4,
        imgSrc: MainPro5,
        projectType: '',
        projectTitle: 'Renovation Made Easy',
        text: ''
    },
    {
        id: 5,
        imgSrc: MainPro6,
        projectType: '',
        projectTitle: 'Modernize Your Home',
        text: 'Modernize your home with our contemporary window treatments, seamlessly blending style and functionality to create a sleek and sophisticated living environment.'
    },
    {
        id: 6,
        imgSrc: MainPro7,
        projectType: '',
        projectTitle: 'Revamp with Style',
        text: 'Revamp your space with style using our chic window treatments, infusing every corner with a touch of elegance and personality.'
    }
]
export default MetalWorksProjectData;

