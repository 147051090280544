// import KellerLogo from "../../assets/img/keller-par-logo.jpeg";
import KellerImg1 from "../../assets/img/keller-par1.jpeg";
import KellerImg2 from "../../assets/img/keller-par2.jpeg";
import KellerImg3 from "../../assets/img/keller-par3.jpeg";

// import gutmannLogo from "../../assets/img/gutmann-par-logo.jpeg";
import gutmannImg1 from "../../assets/img/gutmann-par1.jpeg";
import gutmannImg2 from "../../assets/img/gutmann-par2.jpeg";
import gutmannImg3 from "../../assets/img/gutmann-par3.jpeg";
import BluryLoadingImage from "../Loaders/BluryLoadingImage";

const OurPartners = ({ ourPartnersRef }) => {
  return (
    <>
      <section
        className="w-full -mt-[100px]"
        id="OurPartners"
        ref={ourPartnersRef}
      >
        <div className="gr-container pb-[60px] sm:pb-[100px] px-4 sm:px-2 lg:px-0">
          <div className="mb-10">
            <div> ||||||||||||||||||||| </div>
            <h2 className="text-[32px] sm:text-[62px] leading-[36px] sm:leading-[70px] mt-8">
              Our Projects
            </h2>
          </div>
          <div className="flex flex-col sm:flex-row gap-[40px] sm:gap-[40px]">
            <div className="h-[200px] sm:h-[400px] w-full sm:w-[48%] relative overflow-y-hidden">
              <BluryLoadingImage
                image={KellerImg2}
                alt="missing"
                imageStyleClass="!w-[120px] sm:!w-[165px] md:!w-[220px] lg:!w-[267px] h-[65px] sm:h-[115px] md:h-[150px] lg:h-[177px] absolute top-16 sm:top-[120px] md:top-[100px] sm:left-4 md:left-5"
              />
              <BluryLoadingImage
                image={KellerImg1}
                alt="missing"
                imageStyleClass="!w-[120px] sm:!w-[165px] md:!w-[220px] lg:!w-[267px] h-[65px] sm:h-[115px] md:h-[150px] lg:h-[177px] absolute left-[100px] sm:left-auto sm:-right-1 md:right-2 lg:right-0 top-12 sm:top-20 md:top-16 lg:top-11"
              />
              <BluryLoadingImage
                image={KellerImg3}
                alt="missing"
                imageStyleClass="!w-[120px] sm:!w-[165px] md:!w-[220px] lg:!w-[267px] h-[65px] sm:h-[115px] md:h-[150px] lg:h-[177px] absolute left-12 sm:left-auto sm:-right-1 md:right-14 lg:right-10 bottom-3 sm:top-[200px] md:top-[230px] z-1"
              />
            </div>
            <div className="w-full sm:w-[48%] flex flex-col gap-6 justify-center py-8 lg:py-16 pl-0 sm:pl-4 md:pl-16">
              {/* <div className="logo">
                <img src={KellerLogo} alt="logo" className="max-w-[200px]" />
              </div> */}
              <h3 className="text-[32px] sm:text-[38px] leading-[36px] sm:leading-[50px]">
                Commercial Projects
              </h3>
              <p className="text-[15px] font-helvatica-medium">
                At ASNA Construction, we're dedicated to bringing your
                commercial vision to life with our comprehensive range of
                services. From office spaces to retail establishments and
                beyond, our skilled team delivers innovative solutions tailored
                to your business needs, ensuring a seamless process from concept
                to completion. <br /><br />
                Trust us to create inspiring and functional
                environments that elevate your brand and leave a lasting
                impression on clients and customers alike. Whether you're
                looking to revamp your current space or embark on a new venture,
                we're here to make your commercial dreams a reality, delivering
                outstanding results that exceed expectations and set your
                business apart.
              </p>
            </div>
          </div>
          <div className="flex flex-col-reverse sm:flex-row gap-[40px] sm:gap-[40px] mt-4 sm:mt-[150px]">
            <div className="w-full sm:w-[48%] flex flex-col gap-6 justify-center pr-0 sm:pr-4 md:pr-16">
              {/* <div className="logo">
                <BluryLoadingImage image={gutmannLogo} alt='logo' imageStyleClass='max-w-[200px]' />
              </div> */}
              <h3 className="text-[32px] sm:text-[38px] leading-[36px] sm:leading-[50px]">
                Residential Projects
              </h3>
              <p className="text-[15px] font-helvatica-medium">
                At ASNA Construction, we specialize in turning houses into
                homes, offering tailored solutions for all your residential
                needs. From window treatments to complete renovations and new
                builds, our expert team is committed to enhancing the beauty,
                comfort, and functionality of your living space, ensuring it
                reflects your unique style and personality. <br /><br />
                With a focus on quality craftsmanship and attention to detail, we strive to
                create spaces where cherished memories are made and families
                thrive, turning your house into the perfect place to call home.
              </p>
            </div>
            <div className="h-[200px] sm:h-[400px] w-full sm:w-[48%] relative overflow-y-hidden">
              <BluryLoadingImage
                image={gutmannImg1}
                alt="missing"
                imageStyleClass="!w-[120px] sm:!w-[165px] md:!w-[220px] lg:!w-[267px] h-[65px] sm:h-[115px] md:h-[150px] lg:h-[177px] absolute top-16 sm:top-[120px] md:top-[100px] sm:left-4 md:left-5"
              />
              <BluryLoadingImage
                image={gutmannImg2}
                alt="missing"
                imageStyleClass="!w-[120px] sm:!w-[165px] md:!w-[220px] lg:!w-[267px] h-[65px] sm:h-[115px] md:h-[150px] lg:h-[177px] absolute left-[100px] sm:left-auto sm:-right-1 md:right-2 lg:right-0 top-12 sm:top-20 md:top-16 lg:top-11"
              />
              <BluryLoadingImage
                image={gutmannImg3}
                alt="missing"
                imageStyleClass="!w-[120px] sm:!w-[165px] md:!w-[220px] lg:!w-[267px] h-[65px] sm:h-[115px] md:h-[150px] lg:h-[177px] absolute left-12 sm:left-auto sm:-right-1 md:right-14 lg:right-10 bottom-4 sm:top-[200px] md:top-[230px] z-1"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurPartners;
