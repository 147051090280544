import React from "react";
import "./AboutUs.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SlideOne from '../../assets/img/asna-images/portfolio-gallery1.jpeg';
import SlideTwo from '../../assets/img/asna-images/portfolio-gallery2.jpeg';
import SlideThird from '../../assets/img/asna-images/portfolio-gallery3.jpeg';
import SlideFour from '../../assets/img/asna-images/portfolio-gallery4.jpeg';
import SlideFive from '../../assets/img/asna-images/portfolio-gallery5.jpeg';

import BluryLoadingImage from "../../components/Loaders/BluryLoadingImage";

const PortfolioSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
    <div className="relative portfolio-banner mt-[60px] sm:mt-[90px]">
       <Slider {...settings}>
        <BluryLoadingImage image={SlideOne} alt='portfolio-img' />
        <BluryLoadingImage image={SlideTwo} alt='portfolio-img' />
        <BluryLoadingImage image={SlideThird} alt='portfolio-img' />
        <BluryLoadingImage image={SlideFour} alt='portfolio-img' />
        <BluryLoadingImage image={SlideFive} alt='portfolio-img' />
      </Slider>
    </div>
    </>
  );
};

export default PortfolioSlider;