import MainPro1 from '../../../assets/img/asna-images/construction/Build-Your-Dream-Home.jpg';
import MainPro2 from '../../../assets/img/asna-images/construction/Language-of-Colors.jpg';
import MainPro3 from '../../../assets/img/asna-images/construction/Your-Vision.jpg';
import MainPro4 from '../../../assets/img/asna-images/construction/Start-Building-Today.jpg';
// import MainPro5 from '../../../assets/img/asna-images/construction/contruction5.jpeg';
// import MainPro6 from '../../../assets/img/asna-images/construction/contruction6.jpeg';
// import MainPro7 from '../../../assets/img/asna-images/construction/contruction7.jpeg';

const MaintenanceProjectData = [
    {
        id: 0,
        imgSrc: MainPro1,
        projectType: '',
        projectTitle: 'Build Your Dream Home',
        text: 'Build your dream home with our tailored treatments, adding the perfect finishing touch to every room, and creating a space that reflects your unique style and vision.'
    },
    {
        id: 1,
        imgSrc: MainPro2,
        projectType: '',
        projectTitle: 'Language of Colors',
        text: "Choose paints wisely; colors enhance your home's architecture, making spaces look more spacious and lively."
    },
    {
        id: 2,
        imgSrc: MainPro3,
        projectType: '',
        projectTitle: 'Your Vision, Our Expertise',
        text: 'Your vision, our expertise: Together, we create stunning spaces tailored to your style and needs.'
    },
    {
        id: 3,
        imgSrc: MainPro4,
        projectType: '',
        projectTitle: 'Start Building Today',
        text: `Begin your journey today: Let our expertise bring your vision to life, shaping spaces that inspire and delight.`
    },
    // {
    //     id: 4,
    //     imgSrc: MainPro5,
    //     projectType: '',
    //     projectTitle: 'Tailored Homes, Crafted with Care',
    //     text: 'Façade Sealant Rectification <br /> Client: GBCS Technical Services LLC'
    // },
    // {
    //     id: 5,
    //     imgSrc: MainPro6,
    //     projectType: '',
    //     projectTitle: 'Creating Your Perfect Space',
    //     text: `Replacement of skylights and water & air infiltration repairs <br />
    //     Client: Leaders Fort Contracting LLC`
    // },

    // {
    //     id: 6,
    //     imgSrc: MainPro7,
    //     projectType: '',
    //     projectTitle: 'Building Beyond Expectations',
    //     text: `Air and water leakage repairs Façade Sealant Repairs <br /> Client: ALDAR Properties PJSC`
    // },
]
export default MaintenanceProjectData;

