import React from "react";

const WhatWeDo = ({ whatWeDoRef }) => {
  return (
    <section id="WhatWeDo" className="w-full" ref={whatWeDoRef}>
      <div className="gr-container py-[60px] sm:py-[100px] px-4 sm:px-2 lg:px-0 flex flex-col sm:flex-row justify-between">
        <div className="w-full sm:w-[30%] md:w-[24%] mb-6 sm:mb-0">
          <div className="">
            <div className="px-2 py-0 bg-gray-300 w-max rounded-[4px] mb-6 w-max">
              <span className="uppercase text-[13px] font-helvatica-medium text-[#000]">
                What We Do
              </span>
            </div>
            <h3 className="text-[34px] font-helvatica-bold leading-[40px]">
              Building the future
            </h3>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-12 w-full sm:w-[70%] md:w-[76%]">
          <div className="flex flex-col gap-3 sm:gap-5">
            <div className="flex gap-6">
              <div className="text-[24px] font-helvatica-bold leading-[30px]">
                01
              </div>
              <h3 className="text-[14px] font-helvatica-medium"> / About </h3>
            </div>
            <p className="text-[13px] font-helvatica-medium">
              Welcome to ASNA Construction, where we turn dreams into reality.
              From stunning window treatments to transformative renovations and
              expertly crafted new builds, we offer personalized services that
              reflect your unique vision and style. With years of experience and
              a dedication to quality craftsmanship, we're here to bring your
              residential or commercial project to life, exceeding expectations
              every step of the way.
            </p>
          </div>
          <div className="flex flex-col gap-3 sm:gap-5">
            <div className="flex gap-6">
              <div className="text-[24px] font-helvatica-bold leading-[30px]">
                02
              </div>
              <h3 className="text-[14px] font-helvatica-medium"> / Quality </h3>
            </div>
            <p className="text-[13px] font-helvatica-medium">
              From meticulous installation techniques to rigorous quality
              control measures, we ensure that every aspect of your project
              meets our exacting standards. With a focus on durability,
              functionality, and aesthetics, we strive to deliver results that
              not only meet but exceed your expectations, leaving you with
              spaces that stand the test of time and inspire admiration.
            </p>
          </div>
          <div className="flex flex-col gap-3 sm:gap-5">
            <div className="flex gap-6">
              <div className="text-[24px] font-helvatica-bold leading-[30px]">
                03
              </div>
              <h3 className="text-[14px] font-helvatica-medium"> / Service </h3>
            </div>
            <p className="text-[13px] font-helvatica-medium">
              Service isn't just a word; it's our commitment to your
              satisfaction. From the moment you reach out to us, our dedicated
              team is here to listen to your needs, offer expert advice, and
              guide you through every step of the process. We understand that
              every project is unique, which is why we take the time to tailor
              our services to your specific requirements, ensuring a
              personalized experience from start to finish. With prompt
              communication, transparent pricing, and a focus on exceeding your
              expectations, we're dedicated to providing unparalleled service
              that leaves you delighted with the results. Your satisfaction is
              our priority, and we're here to go above and beyond to make your
              vision a reality.
            </p>
          </div>
          <div className="flex flex-col gap-3 sm:gap-5">
            <div className="flex gap-6">
              <div className="text-[24px] font-helvatica-bold leading-[30px]">
                04
              </div>
              <h3 className="text-[14px] font-helvatica-medium">/ Solution</h3>
            </div>
            <p className="text-[13px] font-helvatica-medium">
              Whether it's finding the perfect window treatment, executing a
              flawless renovation, or building your dream home from the ground
              up, we're committed to delivering comprehensive solutions that are
              tailored to your unique vision and requirements. With our
              expertise and dedication to excellence, we're here to solve your
              home improvement challenges and create spaces that inspire and
              delight.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
