import React from "react";

const ConMap = () => {
  return (
    <section className="w-full">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d433650.333897769!2d-97.06324812363586!3d32.82270943360509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c19f77b45974b%3A0xb9ec9ba4f647678f!2sDallas%2C%20TX%2C%20USA!5e0!3m2!1sen!2s!4v1714077291430!5m2!1sen!2s"
        width="100%"
        height="600"
        style={{border: '0'}}
        allowfullscreen=""
        loading="lazy"
        title="location-map-view"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </section>
  );
};

export default ConMap;

