import { Link } from "react-router-dom";
import CompanyOverView from "../../../assets/img/asna-images/asna-best-window-blinds-shades-provider-dallas-texas.jpg";
import BluryLoadingImage from "../../Loaders/BluryLoadingImage";

const Projects = () => {
  return (
    <section className="gr-container py-[60px] sm:py-[100px] px-0 sm:px-2 lg:px-0">
      <div className="flex flex-col gap-[20px] sm:gap-[60px]">
        <div className="px-4 sm:px-2 lg:px-0"> ||||||||||||||||||||| </div>
        <h2 className="text-[32px] sm:text-[62px] leading-[36px] sm:leading-[68px] px-4 sm:px-2 lg:px-0">
          Window Treatments.
        </h2>
        <div className="flex flex-col sm:flex-row relative">
          <div className="w-full sm:w-[40%] mt-0 sm:mt-[100px] py-[60px] pl-4 sm:pl-6 pr-4 md:pr-[110px] lg:pr-[150px] bg-[#eeeff0] flex flex-col gap-[20px] sm:gap-[40px]">
            <h3 className="text-[32px] sm:text-[38px] leading-[36px] sm:leading-[50px]">
              Expert Window Solutions
            </h3>
            <p className="font-helvatica-medium">
              We recognize that windows are more than just functional; they're
              essential for the aesthetic and practical appeal of your home. Our
              skilled team specializes in seamless window installation and
              treatments, ensuring that each window not only fits flawlessly but
              also enhances your space's overall ambiance. Whether you're
              updating existing windows or installing new ones, we offer a
              diverse range of options tailored to your style and requirements.
              From energy-efficient solutions to elegant treatments like wood
              blinds, faux wood blinds, vertical blinds, and aluminum blinds,
              we're committed to delivering exceptional craftsmanship and
              unparalleled customer service. Let us turn your windows into
              stunning focal points that elevate the beauty and comfort of your
              home.
            </p>
            <Link to="/windows-treatment" className="gr-btn w-max">
              Read More
            </Link>
          </div>
          <div className="w-full sm:w-[60%] relative sm:absolute right-0 sm:right-[80px]">
            <BluryLoadingImage image={CompanyOverView} alt="missing" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
