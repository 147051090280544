import CompanyOverView from "../../assets/img/asna-images/asna-glass-web.jpeg";
import Logo from "../../assets/img/logo/asnawhitebg.png";
import { Link } from "react-router-dom";
import BluryLoadingImage from "../Loaders/BluryLoadingImage";

const WebOverView = ({ glassFieldsRef }) => {
  return (
    <section
      className="w-full bg-[#eeeff0]"
      id="Glassfields"
      ref={glassFieldsRef}
    >
      <div className="gr-container py-[60px] sm:py-[100px] px-4 sm:px-2 lg:px-0">
        <div className="flex flex-col sm:flex-row gap-[40px] sm:gap-[40px]">
          <div className="w-full sm:w-[48%]">
            <BluryLoadingImage
              image={CompanyOverView}
              alt="missing"
              imageStyleClass="w-full"
            />
          </div>
          <div className="w-full sm:w-[48%] flex flex-col gap-6 justify-center pl-0 sm:pl-10 md:pl-16">
            <div className="logo">
              <img src={Logo} alt="asna" className="w-[120px]" />
            </div>
            <p className="text-[15px] font-helvatica-medium">
              Glassifieds, a subsidiary of ASNA Metal. provides high quality
              glass and mirrors that could be deliver right at your doorstep.
              Choose from our wide range of products to get the perfect fit for
              your home renovation needs. It does not get any easier to shop as
              it does with Glassifieds!
            </p>
            <Link to="/" className="gr-btn w-max">
              <span> View Website </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebOverView;
