import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import UserQuestions from "../../../assets/img/asna-images/questionair.jpeg";
import { Collapse, theme } from "antd";
import { Link } from "react-router-dom";
import BluryLoadingImage from "../../Loaders/BluryLoadingImage";

const Questionare = () => {
  const onChange = (key) => {
    // console.log(key);
  };
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const getItems = (panelStyle) => [
    {
      key: "1",
      label:
        "What are the benefits of hiring a reliable service provider for window glass design?",
      children: (
        <p>
          {" "}
          Professional glass companies guarantee a quick, reliable, and
          outstanding service. In addition to that, they listen to your needs,
          guide you through the selection process, offer new options, and assist
          you in making an informed decision. If you want your glass windows to
          look elegant and last longer, it is always best to hire ASNA Construction
          Metal, one of the leading glass and aluminium companies in the UAE.{" "}
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: "How to pick the right sliding windows for your house?",
      children: (
        <p>
          {" "}
          There are various types of sliding windows available for selection.
          Depending on the interior design of the structure and the climate in
          the area, sliding windows can have frames constructed of a variety of
          materials, such as steel, timber, aluminium, or uPVC. To ensure the
          longevity and strength of sliding windows, you have to choose the best
          materials. Hiring a professional glass company will help you pick the
          right sliding windows for your house. We have years of experience, and
          we will be able to figure out the best sliding window that goes well
          with your house design and your needs.{" "}
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label:
        "What's better for glass balconies: toughened glass or laminated glass?",
      children: (
        <p>
          {" "}
          Choosing laminated glass can be a much better alternative if you want
          a more flexible glass railing that has a higher level of UV protection
          and is more resistant to sound. Overall, the type of glass you select
          for the railing of your glass balcony will vary depending on your
          requirements. Whether it is for a home or commercial construction, you
          should take into account the aforementioned considerations when making
          your final decision. We are one of the best glass manufacturers in the
          UAE and can help you find the best glass for you.{" "}
        </p>
      ),
      style: panelStyle,
    },
  ];
  return (
    <section className="gr-container py-[60px] sm:py-[100px] px-4 sm:px-2 lg:px-0">
      <div className="flex flex-col gap-[20px] sm:gap-[60px]">
        <div className="flex flex-col sm:flex-row relative">
          <div className="w-full sm:w-[58%] pr-0 sm:pr-[50px] pb-[50px] sm:pb-[100px] flex flex-col gap-[20px] sm:gap-[40px]">
            <div> ||||||||||||||||||||| </div>
            <h3 className="text-[32px] sm:text-[38px] leading-[36px] sm:leading-[50px]">
              Got any questions?
            </h3>
            <div>
              <Collapse
                className="font-helvatica-medium"
                defaultActiveKey={["1"]}
                ghost
                onChange={onChange}
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                style={{
                  background: token.colorBgContainer,
                }}
                items={getItems(panelStyle)}
              />
            </div>
            <Link to="#" className="gr-btn w-max">
              View More
            </Link>
          </div>
          <div className="w-full sm:w-[50%] relative sm:absolute right-0 sm:right-[-80px]">
            <BluryLoadingImage image={UserQuestions} alt="missing" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questionare;
