import { useState } from "react";
import { Link } from "react-router-dom";
import AsnaLogo from "../../assets/img/logo/asnawhitebg.png";
import { CloseOutlined, CaretDownOutlined } from '@ant-design/icons';
import BluryLoadingImage from "../Loaders/BluryLoadingImage";

const DesktopMenu = ({ closeMenu }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={`w-full h-[100vh] bg-overlay-main overflow-hidden fixed top-0 right-0 left-0 bottom-0 z-[999]`}>
      <div className="w-full max-w-[1024px] bg-overlay mx-auto flex flex-col sm:flex-row justify-center items-start px-4 sm:px-2 py-[40px] sm:py-[70px] relative">
        <div className="absolute top-[50px] sm:top-[100px] right-5 sm:right-0 cursor-pointer" onClick={closeMenu}> 
          <CloseOutlined className="text-[#000] text-[28px] sm:text-[36px]" /> 
        </div>
        <div className="w-full sm:w-[70%]">
          <div className="logo w-full h-full z-10">
              <Link to='/'>
                <BluryLoadingImage image={AsnaLogo} alt="ASNA Construction" imageStyleClass='!w-[140px] sm:!w-[170px] h-full' />
              </Link>
          </div>
          <div className="py-6"> ||||||||||||||||||||| </div>
          <div className="menu ">
            <div
              className="flex flex-col items-start md:gap-4 w-full gap-[14px] z-[100]"
            >
              <Link to="/" className="flex hover:text-[#fd6050] bg-fixed transition duration-300 ease-in-out rounded-lg">
                  <span className='font-helvatica-bold text-[26px] sm:text-[42px]' onClick={closeMenu}>
                      Home
                  </span>
              </Link>
              <Link to="/about-us" className="flex hover:text-[#fd6050] bg-fixed transition duration-300 ease-in-out rounded-lg">
                  <span className='font-helvatica-bold text-[26px] sm:text-[42px]' onClick={closeMenu}>
                      About Us
                  </span>
              </Link>
              <div className="relative"> 
                {/* Dropdown Trigger */}
                <div
                  className="flex hover:text-[#fd6050] bg-fixed transition duration-300 ease-in-out rounded-lg cursor-pointer"
                  onClick={toggleDropdown}
                >
                  <span className="font-helvatica-bold text-[26px] sm:text-[42px]">
                    Services <CaretDownOutlined className='text-[24px]' />
                  </span>
                </div>

                {/* Dropdown Content */}
                {isDropdownOpen && (
                  <div className={`mt-2 ml-8 ${isDropdownOpen ? 'dropdown-display' : 'dropdown-out'}`}>
                    {/* Dropdown Items */}
                    <div className="py-1">
                      <Link
                        to="/windows-treatment"
                        className="block font-helvatica-medium text-[20px] sm:text-[28px] text-[#838486] hover:text-[#fd6050]"
                        onClick={closeMenu}
                      >
                        Windows Treatments
                      </Link>
                      <Link
                        to="/house-construction"
                        className="block my-2 font-helvatica-medium text-[20px] sm:text-[28px] text-[#838486] hover:text-[#fd6050]"
                        onClick={closeMenu}
                      >
                        House Construction
                      </Link>
                      <Link
                        to="/house-renovation"
                        className="block font-helvatica-medium text-[20px] sm:text-[28px] text-[#838486] hover:text-[#fd6050]"
                        onClick={closeMenu}
                      >
                        House Renovation
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              <Link to="/contact-us" className="flex hover:text-[#fd6050] bg-fixed transition duration-300 ease-in-out rounded-lg">
                  <span className='font-helvatica-bold text-[26px] sm:text-[42px]' onClick={closeMenu}>
                      Contact Us
                  </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-[30%] hidden sm:block">
          <div className="flex flex-col justify-start items-start pt-0 pt-[150px]">
            <div> ||||||||||||||||||||| </div>
            <h2 className="flex transition duration-300 ease-in-out rounded-lg mt-4">
              <span className='font-helvatica-bold text-[26px] sm:text-[42px] leading-[32px] sm:leading-[48px]'>
                Have a project in mind?
              </span>
            </h2>
            <p className='font-helvatica-medium mt-4'> Through a masterful combination of engineering, construction, design disciplines and expertise </p>
            <Link to="/contact-us" className="build-btn w-max" onClick={closeMenu}> Let's Build </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DesktopMenu;
