
import "./AboutUs.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import BluryLoadingImage from "../../components/Loaders/BluryLoadingImage"

import SlideOne from '../../assets/img/asna-images/about-banner1.jpeg';
import SlideTwo from '../../assets/img/asna-images/about-banner2.jpeg';
import SlideThird from '../../assets/img/asna-images/about-banner.jpeg';


const AboutBanner = () => {

    const settings = {
      dots: false,
      fade: true,
      infinite: true,
      speed: 1000,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      waitForAnimate: false,
    };

  return (
    <>
    <div className="relative about-banner">
       <Slider {...settings}>
          <BluryLoadingImage image={SlideOne} alt='portfolio-img' />
          <BluryLoadingImage image={SlideTwo} alt='portfolio-img' />
          <BluryLoadingImage image={SlideThird} alt='portfolio-img' />
      </Slider>
    </div>
    </>
  );
};

export default AboutBanner;

