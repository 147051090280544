import React, { useRef } from 'react';
import AboutHeader from '../../components/AboutUs/AboutHeader'
import AboutBanner from '../../components/AboutUs/AboutBanner'
import WhatWeDo from '../../components/AboutUs/WhatWeDo'
import WebOverView from '../../components/AboutUs/WebOverView'
import PortfolioSlider from '../../components/AboutUs/PortfolioSlider'
import OurPartners from '../../components/AboutUs/OurPartners'

const AboutUs = () => {

  const whatWeDoRef = useRef(null);
  const glassFieldsRef = useRef(null);
  const ourPartnersRef = useRef(null);

  const ScrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // Apply offset using CSS
      window.scrollTo({
        top: window.scrollY - 100, // Adjust the value as needed
        behavior: 'smooth'
      });
    }
  };


  return (
    <article>
      <AboutHeader 
        ScrollToSection={ScrollToSection} whatWeDoRef={whatWeDoRef} 
        glassFieldsRef={glassFieldsRef} ourPartnersRef={ourPartnersRef} />
      <AboutBanner />
      <WhatWeDo whatWeDoRef={whatWeDoRef} />
      <WebOverView glassFieldsRef={glassFieldsRef} />
      <PortfolioSlider />
      <OurPartners ourPartnersRef={ourPartnersRef} />
    </article>
  )
}

export default AboutUs