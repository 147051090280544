import React, { useState } from "react";
import Swal from "sweetalert2";

const ConForm = () => {
  const [formInputs, setFormInputs] = useState({
    your_name: "",
    email_address: "",
    phone_number: "",
    interested_service: "",
    requirements: "",
  });
  const InputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const HandleSubmit = (e) => {
    e.preventDefault();

    if (window?.Email && formInputs) {
        const bodyCode = `
        <div>
          <h3>User Name: ${formInputs?.your_name} </h3>
          <h3>Email Address: ${formInputs?.your_email}</h3>
          <h3>Phone Number: ${formInputs?.phone_number}</h3>
          <h3>Phone Number: ${formInputs?.interested_service}</h3>
          <h3>Requirements: ${formInputs?.requirements}</h3>
        </div>
      `;
      const config = {
        SecureToken: "95e023d6-2252-4a2c-a0dc-dafc75ddb1f9",
        From: "waqar.sarwar@psi-square.net",
        To: "waqar.sarwar@psi-square.net",
        Subject: "ASNA Construction",
        Body : bodyCode
      };
      window.Email.send(config)
        .then(
          Swal.fire({
            text: "Your message submitted successfully",
            icon: "success",
            confirmButtonText: "OK",
          })
        )
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <section className="w-full">
      <div className="gr-container !max-w-[1024px] pb-[50px] sm:pb-[100px] px-4 md:px-2 lg:px-0">
        <form onSubmit={HandleSubmit}>
          <div className="border border-[#c6c6c6] flex flex-col sm:flex-row w-full">
            <div className="flex flex-col gap-2 p-4 sm:p-6 w-full sm:w-1/2">
              <label htmlFor="your_name" className="">
                Nice to meet you
              </label>
              <input
                id="your_name"
                name="your_name"
                type="text"
                placeholder="What's your name"
                className="outline-none"
                value={formInputs?.your_name}
                onChange={InputChange}
              />
            </div>
            <div className="flex flex-col gap-2 p-4 sm:p-6 border-t sm:border-t-0 sm:border-l border-[#c6c6c6] w-full sm:w-1/2">
              <label htmlFor="your_email"> Email address </label>
              <input
                id="your_email"
                name="your_email"
                type="email"
                placeholder="Enter your email address"
                className="outline-none"
                value={formInputs?.your_email}
                onChange={InputChange}
              />
            </div>
          </div>
          <div className="border border-[#c6c6c6] border-t-0 flex flex-col sm:flex-row">
            <div className="flex flex-col gap-2 p-4 sm:p-6 w-full sm:w-1/2">
              <label htmlFor="phone_number"> Phone </label>
              <input
                id="phone_number"
                name="phone_number"
                type="text"
                placeholder="Your phone number"
                className="outline-none"
                value={formInputs?.phone_number}
                onChange={InputChange}
              />
            </div>
            <div className="flex flex-col gap-2 p-4 sm:p-6 border-t sm:border-t-0 sm:border-l border-[#c6c6c6] w-full sm:w-1/2">
              <label htmlFor="interested_service"> Interested Service </label>
              <input
                id="interested_service"
                name="interested_service"
                type="text"
                placeholder="Facade Maintenance"
                className="outline-none"
                value={formInputs?.interested_service}
                onChange={InputChange}
              />
            </div>
          </div>
          <div className="border border-[#c6c6c6] border-t-0 w-full">
            <div className="flex flex-col gap-2 p-4 sm:p-6">
              <label htmlFor="requirements"> Let's talk </label>
              <textarea
                id="requirements"
                name="requirements"
                type="text"
                placeholder="Tell us about your project"
                className="outline-none h-[100px] resize-none"
                value={formInputs?.requirements}
                onChange={InputChange}
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-[#000] hover:bg-[#fd6050] py-4 text-white mt-5 hover:transition-all duration-700"
          >
            {" "}
            Send Message{" "}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ConForm;
