import React from "react";
import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <section className="gr-container py-[60px] sm:py-[100px] px-4 sm:px-2 lg:px-0">
      <div className="flex flex-col gap-[30px]">
        <div> ||||||||||||||||||||| </div>
        <h2> About us </h2>
        <p className="font-helvatica-medium ">
          We at ASNA Construction, Revitalize your living environment with
          exquisite window solutions. At our comprehensive store, discover an
          impressive array of shades, blinds, shutters, soft treatments, and
          drapery, all tailored to elevate your space. Our commitment lies in
          assisting you in discovering the perfect window coverings that
          harmonize with your unique taste and requirements. Whether it's
          managing light, ensuring privacy, enhancing energy efficiency, or
          adding a dash of sophistication, we cater to every window and envision
          every dream home. Book a complimentary in-home consultation today and
          allow our adept design professionals to navigate you through the
          selection process, ensuring a flawless fit and a style that resonates
          with you.
        </p>
        <Link to="/about-us" className="gr-btn w-max">
          Read More
        </Link>
      </div>
    </section>
  );
};

export default AboutSection;
