import React from "react";
import AttachedBg from "../../../assets/img/attached-image-bg.jpg";
import "./AfterMetalBg.css";

const AfterMetalBg = () => {
  const AssuranceBgStyle = { backgroundImage: `url(${AttachedBg})` };
  return (
    <section className="w-full aftermetal-main " style={AssuranceBgStyle}>
      <div className="gr-container py-[100px] sm:py-[250px]"></div>
    </section>
  );
};

export default AfterMetalBg;
