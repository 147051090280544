import React from 'react'

const ConDetails = () => {
  return (
    <section className='w-full'>
        <div className='gr-container py-[30px] sm:py-[90px] px-4 md:px-2 lg:px-0'>
            <div className="py-6"> ||||||||||||||||||||| </div>
            <h1 className='text-[42px] !leading-[56px] sm:!leading-[112px] sm:text-[104px]'> Let's Talk. </h1>
            <div className='flex flex-col gap-3'>
                <p className='text-[15px] font-helvatica-medium'> 
                    We’re interested in working together 
                </p>
                <p className='text-[15px] font-helvatica-medium w-full sm:w-[25%]'> 
                    Dallas, Texas 
                </p>
                {/* <a href="tel: +971 4 399 8550" className='text-[16px] font-helvatica-medium'> 
                    +971 4 399 8550 
                </a> */}
                <a href='mailto: info@glassroom.ae' className='text-[16px] font-helvatica-medium'> 
                    farhan@asnaconstruction.com
                </a>
            </div>
        </div>
    </section>
  )
}

export default ConDetails