import { useState, useEffect } from "react";
import { CaretDownOutlined } from "@ant-design/icons";

const AboutHeader = () => {
  const [textContent, setTextContent] = useState("Architecture");

  useEffect(() => {
    const interval = setInterval(() => {
      setTextContent((prevText) => {
        switch (prevText) {
          case "Architecture":
            return "Glass works";
          case "Glass works":
            return "Construction";
          case "Construction":
            return "Metal works";
          case "Metal works":
            return "Maintenance";
          case "Maintenance":
            return "Architecture.";
          default:
            return "Architecture";
        }
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleNavLinkClick = (event, targetId) => {
    event.preventDefault(); // Prevent default behavior of anchor tag
    
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
        const targetRect = targetElement.getBoundingClientRect();

        const targetScrollPosition =
            window.scrollY + targetRect.top - 100; // Subtracting 100px from the top

        const finalScrollPosition = Math.max(0, targetScrollPosition);

        window.scrollTo({
            top: finalScrollPosition,
            behavior: "smooth",
        });
    }
};

  return (
    <section className="w-full" id="navbar">
      <div className="gr-container py-[30px] sm:py-[90px] px-4 md:px-2 lg:px-0 flex flex-col sm:flex-row justify-between items-end">
        <div className="w-full sm:w-[80%]">
          <div className="py-6"> ||||||||||||||||||||| </div>
          <div className="px-3 pt-1 bg-[#000] w-max rounded-full">
            <span className="uppercase text-[14px] font-helvatica-medium text-[#fff]">
              About Us
            </span>
          </div>
          <h1 className="text-[38px] !leading-[56px] sm:!leading-[112px] sm:text-[104px] pt-6">
            {textContent}
          </h1>
        </div>

        <div className="flex flex-col gap-3 w-full sm:w-[20%] pb-5">
          <a
            className="flex justify-between items-center"
            aria-current="page"
            onClick={(event) => handleNavLinkClick(event, "WhatWeDo")}
            href="#WhatWeDo"
          >
            <span className="text-[14px] font-helvatica-medium"> What We Do </span>
            <CaretDownOutlined className="text-[12px]" />
          </a>
          <a
            className="flex justify-between items-center border-y border-gray-300 py-3"
            aria-current="page"
            onClick={(event) => handleNavLinkClick(event, "Glassfields")}
            href="#Glassfields"
          >
            <span className="text-[14px] font-helvatica-medium"> Glassifieds </span>
            <CaretDownOutlined className="text-[12px]" />
          </a>
          <a
            className="flex justify-between items-center"
            aria-current="page"
            onClick={(event) => handleNavLinkClick(event, "OurPartners")}
            href="#OurPartners"
          >
            <span className="text-[14px] font-helvatica-medium">
              Meet the Partners
            </span>
            <CaretDownOutlined className="text-[12px]" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutHeader;
