import React from 'react'
import ConDetails from '../../components/ContactUs/ConDetails'
import ConForm from '../../components/ContactUs/ConForm'
import ConMap from '../../components/ContactUs/ConMap'

const ContactUs = () => {
  return (
    <article>
        <ConDetails />
        <ConForm />
        <ConMap />
    </article>
  )
}

export default ContactUs