import React from "react";
import ResidenceProjectData from "../../components/ProjectsPages/ProjectsData/ResidenceProjectData.js";
import BluryLoadingImage from "../../components/Loaders/BluryLoadingImage.jsx";

const ResidenceProject = () => {
  return (
    <>
      <section className="gr-container py-[60px] sm:py-[100px] px-4 sm:px-2 lg:px-0">
        <div className="flex flex-col gap-[20px] sm:gap-[60px]">
          <div> ||||||||||||||||||||| </div>
          <h2 className="text-[32px] sm:text-[72px] leading-[36px] sm:leading-[72px] w-full sm:w-1/2 ">
            Window Treatments
          </h2>
        </div>
      </section>

      {ResidenceProjectData.map((item, ind) => {
        return (
          <>
            {ind % 2 === 0 ? (
              <section
                key={ind}
                className="gr-container !max-w-[1024px] py-0 sm:py-[100px] px-4 sm:px-2 lg:px-0"
              >
                <div className="flex flex-col sm:flex-row justify-end gap-[40px]">
                  <div className="w-full sm:w-[40%] flex flex-col justify-end gap-2 pb-0 sm:pb-12">
                    <div> ||||||||||||||||||||| </div>
                    <div className="flex flex-col">
                      <h4 className="text-[24px] sm:text-[26px] leading-[36px] sm:leading-[44px]">
                        {item?.projectType}
                      </h4>
                      <h4 className="text-[32px] sm:text-[38px] leading-[36px] sm:leading-[44px] w-full sm:w-[85%]">
                        {item?.projectTitle}
                      </h4>
                    </div>
                    {item?.text && (
                      <p
                        className="font-helvetica-medium w-full sm:w-[90%] text-[#838486] font-helvatica-medium"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      ></p>
                    )}
                    {item?.textList && (
                      <ul className="list-disc ml-3">
                        {item?.textList.map((listItem, ind) => (
                          <li
                            key={ind}
                            className="text-[#838486] font-helvatica-medium "
                          >
                            {listItem.LiText}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div
                    className={`w-full flex justify-end sm:w-[60%]`
                    // ${ ind === 10 ? "sm:w-[40%]" : "sm:w-[60%]" }`
                    }
                  >
                    <BluryLoadingImage image={item?.imgSrc} alt={item?.projectTitle} imageStyleClass='w-auto h-auto' />
                  </div>
                </div>
              </section>
            ) : (
              <section
                key={ind}
                className="gr-container !max-w-[1024px] py-[60px] sm:py-[100px] px-4 sm:px-2 lg:px-0"
              >
                <div className="flex flex-col-reverse sm:flex-row gap-[40px] justify-start">
                  <div
                    className={`w-full flex justify-end sm:w-[60%]` 
                    // ${ ind === 9 || ind === 11 ? "sm:w-[40%]" : "sm:w-[60%]"}`
                  }
                  >
                   <BluryLoadingImage image={item?.imgSrc} alt={item?.projectTitle} imageStyleClass='w-auto h-auto' />
                  </div>
                  <div className="w-full sm:w-[40%] flex flex-col justify-end gap-2 pb-0 sm:pb-12">
                    <div> ||||||||||||||||||||| </div>
                    <div className="flex flex-col">
                      <h4 className="text-[24px] sm:text-[26px] leading-[36px] sm:leading-[44px] text-[#f0483e]">
                        {item?.projectType}
                      </h4>
                      <h4 className="text-[32px] sm:text-[38px] leading-[36px] sm:leading-[44px] text-[#f0483e]">
                        {item?.projectTitle}
                      </h4>
                    </div>
                    {item?.text && (
                      <p
                        className="font-helvetica-medium w-full sm:w-[90%] text-[#838486] font-helvatica-medium"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      ></p>
                    )}
                    {item?.textList && (
                      <ul className="list-disc ml-3">
                        {item?.textList.map((listItem, ind) => (
                          <li
                            key={ind}
                            className="text-[#838486] font-helvatica-medium "
                          >
                            {listItem.LiText}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </section>
            )}
          </>
        );
      })}
    </>
  );
};

export default ResidenceProject;
