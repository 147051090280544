import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./routes/Home/Home";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./routes/AboutUs/AboutUs";
import ContactUs from "./routes/ContactUs/ContactUs";
import ResidenceProject from "./routes/Projects/ResidenceProject";
import MaintenanceProject from "./routes/Projects/MaintenanceProject";
import MetalWorksProject from "./routes/Projects/MetalWorksProject";
import ScrollToTop from './components/ScrollBehavior/ScrollToTop';

const App = () => {
  return (
    <>
    <ScrollToTop>
      <div className="relative">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/windows-treatment" element={<ResidenceProject />} />
          <Route path="/house-construction" element={<MaintenanceProject />} />
          <Route path="/house-renovation" element={<MetalWorksProject />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <Footer />
      </div>
    </ScrollToTop>
    </>
  );
};

export default App;
