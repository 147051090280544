import React from 'react'
import Banner from '../../components/home/Banner/Banner'
import AboutSection from '../../components/home/AboutSection/AboutSection'
import Assurance from '../../components/home/Assurance/Assurance'
import CompanyOV from '../../components/home/CompanyOV/CompanyOV'
import Projects from '../../components/home/Projects/Projects'
import Maintenance from '../../components/home/Maintenance/Maintenance'
import MetalWorks from '../../components/home/MetalWorks/MetalWorks'
import AfterMetalBg from '../../components/home/AfterMetalBg/AfterMetalBg'
import Questionare from '../../components/home/Questionare/Questionare'

const Home = () => {
  return (
    <>
        <Banner />
        <AboutSection />
        <Assurance />
        <CompanyOV />
        <Projects />
        <Maintenance />
        <MetalWorks />
        <AfterMetalBg />
        <Questionare />
    </>
  )
}

export default Home