import { Link } from "react-router-dom";
import CompanyOverView from "../../../assets/img/asna-images/windows/asna-best-window-treatment-asna.jpg";
import BluryLoadingImage from "../../Loaders/BluryLoadingImage";

const CompanyOV = () => {
  return (
    <section className="gr-container py-[60px] sm:py-[100px] px-4 sm:px-2 lg:px-0">
      <div className="flex flex-col sm:flex-row gap-[20px] items-center sm:gap-[40px]">
        <div className="w-full sm:w-[48%] flex flex-col gap-[20px] sm:gap-[40px]">
          <div> ||||||||||||||||||||| </div>
          <h2 className="text-[28px] sm:text-[42px] leading-[36px] sm:leading-[50px]">
            Best Window Treatment company in Taxes
          </h2>
          <p className="font-helvatica-medium">
            We specialize in crafting exquisite window treatments, including
            shades, blinds, shutters, soft treatments, and drapery, each
            meticulously tailored to enhance the ambiance of your space. Our
            skilled artisans devote attention to detail in creating these
            elements, ensuring they seamlessly integrate with your interior
            design. Whether you seek to control light, add privacy, or imbue
            your rooms with elegance, our range of window treatments offers
            unmatched versatility and style. With a focus on craftsmanship and
            customization, we elevate ordinary windows into focal points of
            beauty and functionality within your home.
          </p>
          <Link to="/about-us" className="gr-btn w-max">
            Read More
          </Link>
        </div>
        <div className="w-full sm:w-[48%]">
          <BluryLoadingImage image={CompanyOverView} alt="missing" />
        </div>
      </div>
    </section>
  );
};

export default CompanyOV;
