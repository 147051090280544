import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import AsnaLogo from "../../assets/img/logo/asnawhitebg.png";
import { MenuOutlined } from '@ant-design/icons';
import "./Navbar.css";
import DesktopMenu from './DesktopMenu';
import BluryLoadingImage from '../Loaders/BluryLoadingImage';

const Navbar = () => {
  // const currentRoute = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);


  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleToggleMenu =()=>{
    setMenuOpen(!menuOpen);
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const stickyPoint = 1;
      if (currentScrollY > stickyPoint) {
        setSticky(true);
      } else {
        setSticky(false);
      }
      setPrevScrollY(currentScrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);


  return (
    <>
      {
        menuOpen &&
        <DesktopMenu closeMenu={closeMenu} /> 
      }
      <div className={` bg-[#fff] w-full ${isSticky ? 'fixed z-[9] left-0 right-0 is-sticky' : 'static z-[100]' } `}>
        <div className='flex justify-between w-full max-w-[1170px] px-4 sm:px-0 mx-auto py-4'>
          <div className="w-unset sm:w-[20%] cursor-pointer">
            <Link to='/'>
                <BluryLoadingImage image={AsnaLogo} alt="ASNA Construction" imageStyleClass='!w-[80px] sm:!w-[90px]' />
            </Link>
          </div>
          <div className="flex gap-0 md:gap-[30px] w-[40%] sm:w-[80%] items-center justify-end pr-0 md:pr-0">
            <button
              className={`text-[16px] menu-toggle-button text-[#000] -mt-2`}
              onClick={handleToggleMenu}
            >
              <MenuOutlined style={{color: '#000'}} /> MENU
            </button>
            
          </div>
        </div>
      </div>
    </>
    
  )
}

export default Navbar
