import React, { useState, useEffect } from "react";
import { FacebookFilled, InstagramFilled } from '@ant-design/icons'
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const [textContent, setTextContent] = useState('Interior.');

  useEffect(() => {
    const interval = setInterval(() => {

      setTextContent(prevText => {
        switch (prevText) {
          case 'Interior.':
            return 'Design.';
          case 'Design.':
            return 'Offices.';
          case 'Offices.':
            return 'Apartments.';
          case 'Apartments.':
            return 'Façades.';
          default:
            return 'Interior.';
        }
      });
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <footer className="w-full bg-[#1c1c1c]">
      <div className="footer-wrap py-[60px] sm:py-[80px] px-4 sm:px-2 lg:px-0 gr-container flex flex-col gap-8 md:flex-row ">
        <div className="footer-info-sec flex flex-col gap-4 sm:gap-8 w-full md:w-[35%] lg:w-[40%] lg:pb-0 pb-[50px] text-[#fff]">
          <div className="ftr-hdg font-[300] flex flex-col">
            We’d love to build your
            <span className="txt-rotate-keywords">
              <span className='txt-rotate-keyword w-inherit'>
                {textContent}
              </span>
            </span>
          </div>
          <Link to='/contact-us' className='find-btn w-max'> Drop us a line</Link>
          <p className='text-[12px] text-gray-400 italic'> Copyright © 2024 ASNA CONSTRUCTION </p>
        </div>
        <div className="location-sec w-full md:w-[30%] flex flex-col gap-[15px] text-[#fff]">
          <div className="flex flex-col">
            <h4> About us </h4>
            <p className="w-full md:w-[85%]">
              Our team specializes in seamless window treatment and installation, ensuring both aesthetic appeal and functionality. Whether you're looking to update your windows or embark on a complete house renovation, we're dedicated to revitalizing your space with precision and care.
            </p>
          </div>
          <div className="footer-location w-[85%] flex flex-col">
            <h4 className="text-[#fff] text-[18px]"> Contact us </h4>
            <div className="text-[16px] text-[#fff] font-[400] font-helvatica-regular cursor-pointer flex flex-col">
              <a className="w-max">
                {/* Warehouse S62, 6A St.,<br />
                Al Quoz Industrial Area 3,<br /> */}
                Dallas, Texas<br />
              </a>
              <a href="mailto:info@asnaconstructions.com" className="w-max">farhan@asnaconstruction.com</a>
              {/* <a className="w-max">+971 4 242 1375</a> */}
            </div>
          </div>
        </div>
        <div className="location-sec w-full md:w-[35%] flex flex-col text-[#fff] text-left sm:text-right">
          <h4> Quick Links </h4>
          <div className="flex flex-col gap-[8px] mb-2">
            <p> Best window solution company in Dallas </p>
            <p> Best window solution company in Dallas </p>
            <p> Window shades and blinds solution in Texas </p>
            <p> House Renovation in Texas  </p>
            <p> Paint and Tiling in Dallas </p>
            <p> Kitchen and living room renovation in Texas </p>
            <p> Commercial renovation in Dallas Texas </p>
          </div>
          <div className="footer-location flex justify-start sm:justify-end gap-[10px]">
            <a href='#' className="w-[50px] h-[50px] rounded-[50%] text-[#fff] hover:text-[#000] bg-transparent hover:bg-[#fff] border border-[#FFFFFF2E] flex justify-center items-center">
              <InstagramFilled className='text-[20px]' />
            </a>
            <a href='#' className="w-[50px] h-[50px] rounded-[50%] text-[#fff] hover:text-[#000] bg-transparent hover:bg-[#fff] border border-[#FFFFFF2E] flex justify-center items-center">
              <FacebookFilled className='text-[20px]' />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

