import MainProjects from "../../../assets/img/asna-images/dallas-house.jpg";
import { Link } from "react-router-dom";
import BluryLoadingImage from "../../Loaders/BluryLoadingImage";

const Maintenance = () => {
  return (
    <section className="w-full bg-[#eeeff0]">
      <div className="gr-container bg-[#eeeff0] py-[60px] sm:py-[100px]">
        <div className="flex flex-col gap-[20px] sm:gap-[60px]">
          <div className="px-4 sm:px-2 lg:px-0"> ||||||||||||||||||||| </div>
          <h2 className="text-[32px] sm:text-[62px] leading-[36px] sm:leading-[68px] px-4 sm:px-2 lg:px-0">
            House Construction.
          </h2>
          <div className="flex flex-col sm:flex-row justify-end relative">
            <div className="w-full sm:w-[60%] relative sm:absolute left-0 sm:left-[90px]">
              <BluryLoadingImage
                image={MainProjects}
                alt="missing"
                imageStyleClass="!w-full sm:!w-[90%]"
              />
            </div>
            <div className="w-full sm:w-[40%] mt-0 sm:mt-[90px] py-[60px] pr-4 sm:pr-6 pl-4 lg:pl-[60px] bg-[#F0483E] text-[#fff] flex flex-col gap-[20px] sm:gap-[40px]">
              <h3 className="text-[32px] sm:text-[38px] leading-[36px] sm:leading-[50px]">
                Your Dream House
              </h3>
              <p className="font-helvatica-medium">
                We believe that building your dream home should be an exciting
                and stress-free journey. Our experienced team of architects,
                engineers, and builders are dedicated to turning your vision
                into reality with precision and care. From initial design
                concepts to the final touches, we oversee every aspect of the
                construction process to ensure exceptional quality and
                craftsmanship. Whether you're starting from scratch or embarking
                on a renovation project, we prioritize open communication and
                transparency to keep you informed every step of the way. With a
                commitment to superior workmanship and attention to detail,
                we're here to build the perfect home that reflects your style,
                meets your needs, and exceeds your expectations.
              </p>
              <Link to="/house-construction" className="gr-btn w-max">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Maintenance;
